import React from "react";
//import * as ReactDOMServer from 'react-dom/server';

import { useEffect,  useState, useContext, useCallback    } from 'react';

import _ from 'lodash';

import { useParams,
        useNavigate } from 'react-router-dom';

import { strings } from "./../../services/Localization";

import { checkLogin } from "./../../services/Login";
import { dialog, dialogDescription } from '../../components/Common';
import { TAB_ADD_DEVICE, TAB_DEVICE_DETAIL, TAB_VIEW_DATA, TAB_SEND_TO_DEVICE , TAB_APP_ASSIGNMENT, TAB_GROUP_ASSIGNMENT, TAB_BULK_CHANGE_OWNER, TAB_EDIT_BULK_DEVICE } from '../../datatypes/tabsconstants'

declare const constants;

import { dateTimeString, deviceStatusSymbol, deviceStatusTooltip, formatDeveui, getVisibleActions } from '../../utils/filters';


import { faEdit, faDatabase, faPaperPlane, faTrashCan, faCreditCard , faAdd, faRefresh,
         faList, faFileExport, faFileImport, faExclamationTriangle, faExchange,
         faBatteryHalf, faBatteryThreeQuarters, faBatteryExclamation, faBatteryEmpty, faBatteryFull, faBatteryQuarter, faPlug,
        } from '@fortawesome/pro-regular-svg-icons'
import { faExclamationTriangle as solidfaExclamationTriangle }  from '@fortawesome/pro-solid-svg-icons';

import { IDevice, IUser } from "src/dassTypes";

import AppContext from '../../context/AppContext'

import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';

import { ActionType, BulkActionType, ColumnType, DataTableOption } from '../../components/Common/DataTable/DataTypes';

import PayloadExportModal from '../../components/Device/PayloadExportModal';

import { GenericDassQuery, handle429Req } from "../../services/BasicDassQueries";

import { toast } from "./../../utils/Toaster";

import{ getAppBase }  from "../../utils/consts"

import{ DEVEUI_INPUT_VALIDATION, DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";

import PageContent from "../PageContent";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import RenderTagsOverlay from "../../components/Common/RenderTagsOverlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type IRowType = IDevice;

interface IMyDevicesStates {
    loggedUser: IUser | null;
    editDeviceDeveui: string;
    editDeviceIsLegacy: boolean;
    addDevice: boolean;
    showAlertModal: boolean;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    deveui: string;
    selectedAction:string;
    showLoadingDialog:boolean;
    showConfirmDialog:boolean;
    confirmDialogeSettings : {
        title:string;
        description:string;
        actionLabel:string;
        confirmAction: () => void;
    },
    refresh:boolean;
    exportInit:number;
    isRowDeleted?:boolean;
}

const  apps = [];


const MyDevices: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);

    let { deveui, tabname } = useParams();

    const navigate = useNavigate();
    

    const [payloadExportShow, setPayloadExportShow] = useState(false);

    const stateInit = {
            loggedUser: AppContextObj.user,
            editDeviceDeveui: "",
            editDeviceIsLegacy: false,
            addDevice: false,
            pageTitle: strings.MY_APPLICATIONS_TITLE,     // should be translated
            showAlertModal: false,
            breadCrumbArr: [{label: strings.NAV_DEVICES_ITEM, url:''}],
            deveui:'',
            selectedAction:'',
            showLoadingDialog:false,
            showConfirmDialog:false,
            confirmDialogeSettings : {
                title:'',
                description:'',
                actionLabel:'',
                confirmAction: () => {}
            },
            refresh:false,
            exportInit:0,
            isRowDeleted:false
    };

    const [deviceState, setDeviceState] = useState<IMyDevicesStates>(stateInit);


    const refreshTable = (isRowDeleted=false) => {

        setDeviceState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
    }


    const exportTable = () => {
        
        setDeviceState(prevState => {
            let exportInit = (prevState.exportInit === 1)  ? 2 : 1;
            return {...prevState, exportInit:exportInit}
        })
    }


    // const showGroupSelectDialog = () => {
    //     notImplemented()
    // }

    // const showBatchEditDialog = (tf)  => {
    //     notImplemented();
    // }


    const addDevicesToCampaign = async(selectedIds) => {
        if(selectedIds && selectedIds.length > 0) {
            navigate(`${getAppBase()}/my-campaigns/create/`, 
            { state: { selectedDevices:selectedIds,
                row: {}, prevPageUrl: `${getAppBase()}/my-campaigns` } })
        }
    }

    const batchDeleteDevices = async (selectedIds) => {
        const deviceIds = selectedIds.map((row) => {
            return row['deveui'];
        })
        if(deviceIds && deviceIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the ${deviceIds.length} selected device(s)`,
                description: await dialogDescription(deviceIds),
                actionLabel: 'Delete',
            };

            if (await dialog(confirmDialogeSettings) === true) {
                let successCnt = 0;
                for (const deveui of deviceIds) {
                    try {
                        const response = await GenericDassQuery("/rest/nodes/" + deveui, { method: "DELETE" });
                        if (response.status === 429) {
                            await handle429Req("/rest/nodes/" + deveui, { method: "DELETE" }, response.retryAfter, successCnt, "nodes" )
                        }
                        successCnt++;
                    } catch (e) {
                        toast.error(e.message);
                        successCnt--;
                        if (e.status === 429) {
                            break;
                        }
                    }
                }  
                refreshTable(true);
                if (successCnt == deviceIds.length) {
                    toast.success(`${strings.SUCCESSFULLY} ${strings.DELETED} ${successCnt} ${strings.NODES}`);
                }
            }
        }   
    }


    const deleteDevice = async (deveui: string) => {
        const confirmDialogeSettings = {
                title:  "You are about to delete a device",
                description: "Delete '" + formatDeveui(deveui) + "'?",
                actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            // spinnerClass.show();
            try {
                await GenericDassQuery("/rest/nodes/" + deveui, { method: "DELETE" });
                refreshTable(true);
                toast.success("Device successfully deleted");
            } catch (e) {
                toast.error(e.message);
            }
            // spinnerClass.hide();
        }
    }

    const suspendDevice = async (deveui: string) => {
        const confirmDialogeSettings = {
                title:  "You are about to suspend a device",
                description: "Suspend '" + formatDeveui(deveui) + "'?",
                actionLabel: 'Suspend',
                faIcon: solidfaExclamationTriangle
        };

        if (await dialog(confirmDialogeSettings) === true) {

            try {
                await GenericDassQuery("/rest/nodes/" + deveui, { method: "PUT", data: { suspended: true } });
                refreshTable();
                toast.success("Device successfully suspended");
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    const unsuspendDevice = async (deveui: string) => {
        const confirmDialogeSettings = {
                title:  "You are about to Unsuspend a device",
                description: "Unsuspend '" + formatDeveui(deveui) + "'?",
                actionLabel: 'Unsuspend',
                faIcon: faExclamationTriangle
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {
                await GenericDassQuery("/rest/nodes/" + deveui, { method: "PUT", data: { suspended: false } });
                refreshTable();
                toast.success("Device successfully Unsuspended");
            } catch (e) {
                toast.error(e.message);
            }
        }
    }


    const batchSuspendDevices = async (selectedIds) => {
        const deviceIds = selectedIds.map((row) => {
            return row['deveui'];
        })
        if(deviceIds && deviceIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to suspend ${deviceIds.length} the selected device(s)`,
                description: await dialogDescription(deviceIds),
                actionLabel: 'Suspend',
                faIcon: solidfaExclamationTriangle
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(deviceIds.map((deveui) => {
                        return GenericDassQuery("/rest/nodes/" + deveui, { method: "PUT", data: { suspended: true }  });
                    })).then((values) => {
                        refreshTable();
                        toast.success("Devices successfully suspended");
                    });
                } catch (e) {
                    refreshTable();
                    toast.error(e.message);
                }
            }
        }
    }

    const batchUnsuspendDevices = async (selectedIds) => {
        const deviceIds = selectedIds.map((row) => {
            return row['deveui'];
        })
        if(deviceIds && deviceIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to unsuspend ${deviceIds.length} the selected device(s)`,
                description: await dialogDescription(deviceIds),
                actionLabel: 'Unsuspend',
                faIcon: faExclamationTriangle
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(deviceIds.map((deveui) => {
                        return GenericDassQuery("/rest/nodes/" + deveui, { method: "PUT", data: { suspended: false }  });
                    })).then((values) => {
                        refreshTable();
                        toast.success("Devices successfully Unsuspended");
                    });
                } catch (e) {
                    refreshTable();
                    toast.error(e.message);
                }
            }
        }
    }

    const deviceProfileFetcher = async () => {

        const deviceprofileData  = await GenericDassQuery(`/rest/device-profiles?show_region=true`, { method: "GET" });
        deviceProfile = deviceprofileData.data.map(ele => { return { label : ele.profile_name,  value: ele.profile_uuid }})
    }

    const serviceProfileFetcher = async () => {

        const serviceprofileData  = await GenericDassQuery(`/rest/service-profiles?show_region=true`, { method: "GET" });
        serviceProfile = serviceprofileData.data.map(ele => { return { label : ele.profile_name,  value: ele.profile_uuid }});
    }

    const relayProfileFetcher = async () => {
        if (AppContextObj?.user?.can_list_relay_profile) {
            const relayprofileData  = await GenericDassQuery(`/rest/relay-profiles?show_region=true`, { method: "GET" });
            relayProfile = relayprofileData.data.map(ele => { return { label : ele.profile_name,  value: ele.profile_uuid }})
        }
    }

    
    const getActions = () => {


        const actions: ActionType<IRowType>[] = [{
            type: "action",
            text: strings.MY_DEVICES_ACTION_SEND,
            icon: faPaperPlane,
            action: (row) => detailPageNav(navigate, TAB_SEND_TO_DEVICE, row.deveui, row),
            visible: (device) => {
                    return (device?.device_status === 3 
                            && deviceState.loggedUser?.account_status === "active" 
                            && !deviceState.loggedUser?._readonly
                            && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices))
            }
        }, 
        {
            type: "action",
            text: strings.MY_DEVICES_ACTION_DATA,
            icon: faDatabase,
            action: (row) => detailPageNav(navigate, TAB_VIEW_DATA, row.deveui, row),
            visible: () => (deviceState.loggedUser?.account_status === "active")
        },
        {
            type: "action",
            text: strings.MY_DEVICES_ACTION_DELETE,
            icon: faTrashCan,
            visible: () => deviceState.loggedUser?.can_register && !deviceState.loggedUser?._readonly && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices),
            action: (row) => deleteDevice(row.deveui)
        },
        {
            type: "action",
            text: strings.MY_DEVICES_DETAIL,
            icon: faList,
            action: (row) => detailPageNav(navigate, TAB_DEVICE_DETAIL, row.deveui, row),
            visible: () => false
        },
        {
            type: "action",
            text: strings.MY_DEVICE_SUSPEND,
            icon: solidfaExclamationTriangle,
            action: (row) => suspendDevice(row.deveui),
            visible: (row) => {
                return row?.suspended === false 
                       && deviceState.loggedUser?.account_status === "active" 
                       && !deviceState.loggedUser?._readonly
                       && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices)

            } 
        },
        {
            type: "action",
            text: strings.MY_DEVICE_UNSUSPEND,
            icon: faExclamationTriangle,
            action: (row) => unsuspendDevice(row.deveui),
            visible: (row) => {
                return row?.suspended === true 
                       && deviceState.loggedUser?.account_status === "active" 
                       && !deviceState.loggedUser?._readonly
                       && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices)
            } 
        },
        {
            type: "action",
            text: strings.MY_DEVICES_DATA,
            icon: faList,
            action: (row) => detailPageNav(navigate, TAB_VIEW_DATA, row.deveui, row),
            visible: () => false //deviceState.loggedUser?.account_status === "active" 
        }];

        const bulkActions: BulkActionType<IRowType>[] = [
            {
                type: "action",
                text: strings.MY_DEVICES_ACTION_EDIT_DEVICES,
                icon: faCreditCard,
                action: async(selectedIds) => deviceEditBulkAction(navigate, TAB_EDIT_BULK_DEVICE, selectedIds),
                visible: () => {
                    return deviceState.loggedUser?.account_status === "active" 
                           && !deviceState.loggedUser?._readonly
                           && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices)
                } 
            }, 
            {
                type: "action",
                text: strings.MY_DEVICES_ACTION_DELETE_DEVICES,
                icon: faTrashCan,
                action: (selectedIds) => batchDeleteDevices(selectedIds),
                visible: () => { 
                    return deviceState.loggedUser?.account_status === "active" 
                           && !deviceState.loggedUser?._readonly
                           && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices)
                } 
            },
            {
                type: "action",
                text: strings.SUSPEND_DEVICES,
                icon: solidfaExclamationTriangle,
                action: (selectedIds) => batchSuspendDevices(selectedIds),
                visible: () => {
                    return deviceState.loggedUser?.account_status === "active" 
                           && !deviceState.loggedUser?._readonly
                           && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices)
                } 
            },
            {
                type: "action",
                text: strings.UNSUSPEND_DEVICES,
                icon: faExclamationTriangle,
                action: (selectedIds) => batchUnsuspendDevices(selectedIds),
                visible: () => {
                    return deviceState.loggedUser?.account_status === "active" 
                           && !deviceState.loggedUser?._readonly
                           && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices)
                } 
            },
            {
                type: "action",
                text: strings.CHANGE_OWNER,
                icon: faExchange,
                action: async(selectedIds) => deviceBulkAction(navigate, TAB_BULK_CHANGE_OWNER, selectedIds),
                visible: () => {
                    return deviceState.loggedUser?.account_status === "active" 
                           && !deviceState.loggedUser?._readonly
                           && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices)
                } 
            },
            {
                type: "action",
                text: strings.MY_DEVICES_ADD_FUOTA_BTN,
                icon: faAdd,
                action: (selectedIds) => addDevicesToCampaign(selectedIds),
                visible: () => {
                    return !!(deviceState.loggedUser?._license & (1 << 12))
                           && deviceState.loggedUser?.account_status === "active" 
                           && !deviceState.loggedUser?._readonly
                } 
                // visible: () => !!(deviceState.loggedUser?._license & (1 << 12)),
            },
            {
                type: "action",
                text: strings.GROUP_ASSIGNMENT,
                icon: faEdit,
                action: async(selectedIds) => deviceBulkAction(navigate, TAB_GROUP_ASSIGNMENT, selectedIds),
                visible: () => {
                    return deviceState.loggedUser?.account_status === "active" 
                           && !deviceState.loggedUser?._readonly
                           && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices)
                } 
            }, {
                type: "action",
                text: strings.APP_ASSIGNMENT,
                icon: faEdit,
                action: async(selectedIds) => deviceBulkAction(navigate, TAB_APP_ASSIGNMENT, selectedIds),
                visible: () => {
                    return deviceState.loggedUser?.account_status === "active" 
                           && !deviceState.loggedUser?._readonly
                           && (deviceState.loggedUser?.can_register || deviceState.loggedUser?.can_control_devices)
                } 
            }];


        return {
            actions: actions,
            bulkActions:bulkActions
        }
    };


    const getPageButtons = useCallback(() => {
    
        const pageButtons: PageButtonType[] = [
            {
                title: strings.DEVICE_BTN,
                action: () => detailPageAdd(navigate, strings.ADD_DEVICE_BTN),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () => deviceState.loggedUser?.can_register && !deviceState.loggedUser._readonly,
            },
            {
                title: strings.ADD_FUOTA_BTN,
                action: () => addFuotaPage(navigate),
                type: 'button_with_text',
                varient: 'primary',
                icon: faAdd, 
                visible: () => ( !!(deviceState.loggedUser?._license & (1 << 12))
                                 && !deviceState.loggedUser?._readonly ),
            },
            {
                title: strings.IMPORT_DEVICE_BTN,
                action: () => mydeviceExportPageNav(navigate),
                type: 'button',
                varient: 'primary',
                icon: faFileImport,
                visible: () => deviceState.loggedUser?.can_register
                               && !deviceState.loggedUser?._readonly,
            },
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            },
            {
                title: strings.EXPORT_DEVICE_BTN,
                type: 'dropdown_only_icon',
                icon: faFileExport,
                dropDownItems: [
                    {
                        title: strings.NAV_DEVICES_ITEM,
                        action: () => { 
                            exportTable() 
                        },
                    },
                    {
                        title: strings.PAYLOADS,
                        action: () => toggleExportPayloadsModal(),
                    },
                    // {
                    //     title: strings.FUOTA_CAMPAIGN,
                    //     action: () => navigateFuotaCampaign(),
                    // }
                ]
            },
            {
                title: strings.COLUMNS,
                action: () => { console.log(strings.ADD_USER) },
                type: 'column',
				icon: faListCheck
            }
        ];


        return pageButtons;
    },[]);

    let deviceProfile;
    let serviceProfile;
    let relayProfile;

    useEffect(() => {
        
        if(checkLogin(AppContextObj.user)) {
            setDeviceState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }
        deviceProfileFetcher()
        serviceProfileFetcher()
        relayProfileFetcher()
        
    },[])
    
    const deviceBulkAction = ( navigate, tab, selectedIds ) => {

        const idArr = selectedIds.map((row) => {
            return row['deveui'];
        })
        navigate(`${getAppBase()}/my-devices/bulkaction/${tab}`, {state: {tab: tab, selectedIds:idArr, prevPageUrl: `${getAppBase()}/my-devices`}})

    }
    const deviceEditBulkAction = ( navigate, tab, selectedIds ) => {

        const distinct = (value, index, self) => { return self.indexOf(value) === index }
        const Region = selectedIds.map(ele => ele.RFRegion);
        const devicesRegion = Region.filter(distinct)
       
        if (selectedIds.length > 0) {
            const idArr = selectedIds.map((row) => { 
                return row['deveui']
            });
            const RFRegion = devicesRegion.length === 1 ? devicesRegion[0] : "multi_region"
            navigate(`${getAppBase()}/my-devices/bulkaction/${tab}`, {state: {tab: tab, selectedIds:idArr ,RFRegion:RFRegion, prevPageUrl: `${getAppBase()}/my-devices`}})
        } else { 
            toast.error("No Device Selected!");
        }

    }


    const detailPageNav = ( navigate, tab, deveui, row) => {

        navigate(`${getAppBase()}/my-devices/${deveui}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/my-devices`}})
    }

    const toggleExportPayloadsModal = (  ) => {
        setPayloadExportShow(prevState => !payloadExportShow)
    }


    const detailPageAdd = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/my-devices/${TAB_ADD_DEVICE}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/my-devices`}})
    }

    const addFuotaPage = (navigate) => {
        navigate(`${getAppBase()}/my-campaigns`);
    }

    const mydeviceExportPageNav = ( navigate) => {
        
        
        navigate(`${getAppBase()}/mydevice-register`)
    }

    // const navigateFuotaCampaign = () => {
    //     navigate(`${getAppBase()}/my-campaigns`)
    // }

    const renderDeviceProfile = (id) => {
        let display = "";
        try{
            if(deviceProfile && deviceProfile.length > 0){
                const findIndex = deviceProfile.findIndex((res) => res.value == id);
                if(findIndex != -1){
                    display = deviceProfile[findIndex].label
                } 
            }
        } catch(e){
            console.log("e",e)
        }
        
         
        return display;
    }

    const renderServiceProfile = (id) => {
        let display = "";
        try{
            if(serviceProfile && serviceProfile.length > 0) {
                const findIndex = serviceProfile.findIndex((res) => res.value == id);
                if(findIndex != -1){
                    display = serviceProfile[findIndex].label
                } 
            }
        } catch(e){
            console.log("e",e)
        }
        
         
        return display;
    }

    const renderRelayProfile = (id) => {
        let display = "";
        try{
            if(relayProfile && relayProfile.length > 0){
                const findIndex = relayProfile.findIndex((res) => res.value == id);
                if(findIndex != -1){
                    display = relayProfile[findIndex].label
                } 
            }
        } catch(e){
            console.log("e",e)
        }
        
         
        return display;
    }

    const renderBatteryIcon = (level) => {

        if (level == null || level == 255) {
            return null;
        } else if (level === 0) {
            return faPlug;
        } else {
            const percent = Math.round((level - 1) / 253 * 100);

            if (percent > 85) {
                return faBatteryFull;
            } else if (percent > 60) {
                return faBatteryThreeQuarters;
            } else if (percent > 40) {
                return faBatteryHalf;
            } else if (percent > 15) {
                return faBatteryQuarter;
            } else if (percent > 3) {
                return faBatteryEmpty
            } else {
                return faBatteryExclamation;
            }
        }
    }

    const renderBatteryTooltip = (row: IDevice) => {

        const level = row.battery_level;
        if (level == null) {
            return "no status available";
        }
        const txt = "\nOn&#160;" + dateTimeString(row.battery_level_update_time) + "";
        if (level === 255) {
            return "Battery status not supported." + txt;
        }
        if (level === 0) {
            return "Device is attached to power source." + txt
        }
        const percent = Math.round((level - 1) / 253 * 100);
        return "Battery level at " + percent + "%." + txt;
    }





    const  initDataTable = () => {

        const optionFetcher = async () => {
            const pageUrl = '/rest/groups?paged_results=true&limit=2000';
            let result = [];

            try {
                const fetchNextPage = async (pageUrl) => {
                    const response = await GenericDassQuery(pageUrl, { method: "GET" });
                    const groups = response.data.results.map(group => {
                        return { label: group.groupid, value: group.groupid };
                    });
                    result = result.concat(groups);
        
                    const next_page_state = response.data.next_page_state;
                    if (next_page_state) {
                        const nextPageUrl = '/rest/groups?paged_results=true&limit=2000&page_state=' + next_page_state;
                        return fetchNextPage(nextPageUrl);
                    } else {
                        return result;
                    }
                };
                return fetchNextPage(pageUrl);
            } catch (e) {
                console.log(e);
                return result;
            }
        };

        const applicationFetcher = async () => {
            const pageUrl = '/rest/applications?paged_results=true&limit=2000';
            let result = [];

            try {
                const fetchNextPage = async (pageUrl) => {
                    const response = await GenericDassQuery(pageUrl, { method: "GET" });
                    const apps = response.data.results.map(app => {
                        return { label : app.accountid,  value: app.accountid  };
                    });
                    result = result.concat(apps);
        
                    const next_page_state = response.data.next_page_state;
                    if (next_page_state) {
                        const nextPageUrl = '/rest/applications?paged_results=true&limit=2000&page_state=' + next_page_state;
                        return fetchNextPage(nextPageUrl);
                    } else {
                        return result;
                    }
                };
                return fetchNextPage(pageUrl);
            } catch (e) {
                console.log(e);
                return result;
            }
        };
        


        
        const { actions, bulkActions } = getActions();
        const appBase = getAppBase();

        const navigate = useNavigate();
        
        //`${appBase}/groups/`

        const columns: ColumnType<IRowType>[] = [

            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 3,
                newCellWidth: "2%"
            },
            {
                key: "deveui",
                type: "text",
                title: strings.DEVEUI,
                maxInputLength:40,
                filterable: true,
                filterField: "search_deveui",
                filterType: "text",
                inputValidation: DEVEUI_INPUT_VALIDATION,
                filterParams: {
                    mapper: x => (x || "").replace(/[-:\s]/g, "")
                },
                sortable: true,
                sortKey: "sort_by_deveui",
                render: (x) => formatDeveui(x.deveui),
                cellWidth: 50,
                detailLink: true,
                copyLink: true,
                detailPageNav: (row) =>  detailPageNav(navigate, "device-detail", row.deveui, row ),
                customClass: 'font-monospace',
                newCellWidth: "250px"
            },
            {
                key: "image_url",
                title: strings.TABLE_IMAGE,
                type: "icon_with_tooltip",
                filterable: false,
                render: (row) => <img height="50px" src={row.image_url?.startsWith("storageid:") 
                ? "/storagecache/" + row.image_url.substring(10)
                : row.image_url} />,
                cellWidth: 20,
                dataAlign:'center',
                newCellWidth: "100px"
            },
            {
                key: "device_status",
                title: strings.STATUS,
                type: "icon_with_tooltip",
                render_icon: (row) => deviceStatusSymbol(row),
                render_tooltip: (row) => deviceStatusTooltip(row),
                filterable: false,
                filterField: "",
                filterType: "",
                filterParams: {
                    mapper: x => x + "!123"
                },
                dataAlign:'center',
                cellWidth: 6,
                newCellWidth: "70px"
            },
            {
                key: "battery_level",
                dataAlign: "center",
                title: <FontAwesomeIcon icon={faBatteryHalf} />,
                header_tooltip: "Battery Level",
                type: "icon_with_tooltip",
                render_tooltip: (row) => renderBatteryTooltip(row),
                render_icon: (row) => renderBatteryIcon(row.battery_level),
                newCellWidth: "30px"
            },
            {
                key: "comment",
                title: strings.COMMENT,
                type: "text",
                filterable: true,
                filterField: "search_comment",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_comment",
                filterParams: {},
                cellWidth: 50,
                newCellWidth: '250px',
                dataAlign:'left',
            }, 
            {
				key: "device_profile_uuid",
				title: 'Device Profile',
				type: "text",
				enabledInColumn:false,
				newCellWidth: '250px',
                render: (row:IRowType) => renderDeviceProfile(row.device_profile_uuid),
                filterField: 'search_device_profile_uuid',
                filterable: true,
                filterType: "multiselect",
                filterParams: {
                    optionFetcher:() => deviceProfile,
                    data: [],
                    mapper: x => x,
                },
			}, 
            ...(AppContextObj?.user?.can_list_relay_profile ? [{
				key: "relay_profile_uuid",
				title: 'Relay Profile',
				type: "text",
				enabledInColumn:false,
				newCellWidth: '250px',
                render: (row:IRowType) => renderRelayProfile(row.relay_profile_uuid),
                filterField: 'search_relay_profile_uuid',
                filterable: true,
                filterType: "multiselect",
                filterParams: {
                    optionFetcher:() => relayProfile,
                    data: [],
                    mapper: x => x,
                },
			} as ColumnType<IRowType>] : []),
            {
				key: "service_profile_uuid",
				title: 'Service Profile',
				type: "text",
				enabledInColumn:false,
				newCellWidth: '250px',
                filterable: true,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                render: (row:IRowType) => renderServiceProfile(row.service_profile_uuid),
                
                filterField: 'search_service_profile_uuid',
                filterType: "multiselect",
                filterParams: {
                    optionFetcher:() => serviceProfile,
                    data: [],
                    mapper: x => x,
                },
			},  
            {
                key: "groups",
                title: strings.MY_DEVICES_GROUPS,
                type: "text_with_tooltip",
                cellWidth: 25,
                cellWidthType: '%',
                dataAlign: "left",
                // render_tooltip: x => (x.groups || "").replace(/,/g, ', '),
                render: x => { return RenderTagsOverlay(x.groups, 'blue', `${appBase}/groups/~tag~/group-info`, {prevPageUrl:`${getAppBase()}/my-devices`,row: { groupid:0 },}, "Groups") },
                // customNavigation: () => {}, //blank customNavigation will stop whole row clickable behavior so tag clicking can work
                customNavigation: (x) => {
                    if(x.groups){return null} else {
                    detailPageNav(navigate, "device-detail", x.deveui, x )
                }},
                filterable: true,
                filterField: "group",
                filterType: "multiselect",                
                newCellWidth: "150px",
                
                filterParams: {
                    optionFetcher: optionFetcher,
                    data: [],
                    mapper: x => x,
                },
                // extraClass: 'ellipsis-class',
            }
        ];

        if (!deviceState.loggedUser?.app_uuid) {
            // For application accounts we don't show the applications column
            columns.push({
                key: "applications",
                title: strings.MY_DEVICES_APPLICATIONS,
                type: "text_with_tooltip",
                // render_tooltip: x => (x.applications || "").replace(/,/g, ', '),
                render: x => { return RenderTagsOverlay(x.applications, 'blue', `${appBase}/my-apps/~tag~/edit-app`,{prevPageUrl:`${getAppBase()}/my-devices`,row: { accountid:0 }}, "Applications")},
                // customNavigation: () => {}, //blank customNavigation will stop whole row clickable behavior so tag clicking can work
                customNavigation: (x) => {
                    if(x.applications){return null} else {
                    detailPageNav(navigate, "device-detail", x.deveui, x )
                }},
                filterable: true,
                filterField: "application",
                filterType: "multiselect",
                cellWidth: 35,
                cellWidthType: '%',
                dataAlign: "left",
                newCellWidth: "180px",
                filterParams: {
                    optionFetcher: applicationFetcher,
                    data: apps,
                    mapper: (x) => x,
                },
                // extraClass: 'ellipsis-class',
            });
        }

        columns.push({
            key: "last_reception",
            title: strings.LAST_SEEN,
            type: "text",
            filterable: true,
            filterField: "date",
            filterType: 'daterange',
            filterParams: {
                startField: "from_date",
                endField: "to_date",
                mapper: (x) => x && x.format()
            },
            sortable: true,
            sortKey: "sort_by_date",
            newCellWidth: "180px",
            render: x => dateTimeString(x.last_reception),
            cellWidth: 34,
            customClass: 'font-monospace',

        });

        columns.push({
            key: 'action_button',
            type: "action_button",
            title: 'Actions',
            filterable: false,
            cellWidth: 3,
            newCellWidth: "100px"
        });


        const options: DataTableOption<IRowType> = {
            url: '/uiapi/rest/nodes',
            query_param: {all:true, get_pages:true, limit: DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number: false,
            id_field: 'deveui',
            oboe_path: 'pages.*',
            available_key: 'deveui',
            allowBulkActions: true,
            defaultSortField: 'sort_by_deveui',
            defaultSortOrder: 'asc',
            exportPath: 'uiapi/rest/export/devices?download_filename=devices.csv',
            emptyDataMsg: '<b>Sorry!</b> No Device Available',

            emptyDataButton: deviceState.loggedUser?.can_register ? {
                title: strings.DEVICE_BTN,
                action: () => detailPageAdd(navigate, strings.ADD_DEVICE_BTN),
                type: 'button_with_icon',
                icon: faAdd
            } : undefined,

            modal: false,
            columns,
            actions: actions,
            bulkActions: getVisibleActions(bulkActions)
            // ...actions
        }

        return options;
    }


    let refresh = deviceState.refresh;

    return (
        <PageContent
            name="my-device"
            id={deveui}
            tabname={tabname}
            breadCrumbArr={deviceState.breadCrumbArr}   
            pageButtons={getPageButtons()}
            countLabel={`Devices`}
            dataTableOption={initDataTable()}
            exportInit={deviceState.exportInit}
            isRowDeleted={deviceState.isRowDeleted}
            refresh={refresh}>
            <PayloadExportModal handleClose={ () => { toggleExportPayloadsModal() } } show={payloadExportShow}/>
        </PageContent>
    );



}

export default MyDevices;